import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { ArrowLeft, ArrowRight, BookIcon, FullScreenIcon } from "../Icon";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePage(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page
    const handle = useFullScreenHandle();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function changePage(offset) {
        setPageNumber((pageNumber) => pageNumber + offset);
    }

    function previousPage() {
        if (pageNumber > 1) {
            changePage(-1);
        }
    }

    function nextPage() {
        if (pageNumber < numPages) {
            changePage(1);
        }
        // setPageNumber(pageNumber+1)
    }

    const { pdf } = props;

    console.log(pdf, "yooo pdf js ");
    return (
        <FullScreen handle={handle} style={{ overflowY: "auto" }}>
            <div className="container">
                {/* <div className="pdfWrapper">
                    <Document
                        file={{
                            url: pdf,
                        }}
                        options={{ workerSrc: "/pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        renderMode="canvas"
                        // width="800px"
                        width="1000px"
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div> */}
                <div>
                    <iframe src={pdf} width="100%" height="1000px" />
                </div>
                {/* <div className="prevNext" style={{ marginBottom: "50px" }}>
                    <div className="prev">
                        <div disabled={pageNumber <= 1} onClick={previousPage}>
                            <ArrowLeft color={pageNumber <= 1 ? "#707070" : "#30331f"} />
                        </div>
                    </div>

                    <div className="next">
                        <div onClick={nextPage}>
                            <ArrowRight color={pageNumber >= numPages ? "#707070" : "#30331f"} />
                        </div>
                    </div>
                </div> */}
            </div>

            <footer className="readFooter rtl">
                {/* <div className="progressSection">
                    <p className="readSection">
                        You read {pageNumber} page of {numPages} page
                    </p>

                    <progress
                        className="progressBar"
                        value={(pageNumber / numPages) * 100}
                        max="100"
                    >
                        {" "}
                        {(pageNumber / numPages) * 100}{" "}
                    </progress>
                </div> */}

                <div className="allRight">
                    {/* <BookIcon color="#8a9358" /> */}
                    <h1 className="text rtl">© ۱۴۰۲ - ۱۴۰۲ - کلیه حقوق این فروشگاه محفوظ است</h1>
                </div>
                <div className="fullScreen" onClick={handle.enter}>
                    <FullScreenIcon />

                    <p>تمام صفحه</p>
                </div>
            </footer>
        </FullScreen>
    );
}
