import React from "react";
import "./../../styles/Header.scss";
import { Link } from "react-router-dom";
export default function HeaderDiscover() {
    const links1 = [
        { title: "دسته بندی ها", link: "/genres" },
        { title: "انتخاب سردبیر ", link: "/source?sortBy=title&page=1&editor_suggestion=true" },

        { title: "ترندها", link: "/source?sortBy=trending&page=1" },
        { title: "همه منابع", link: "/source?sortBy=title&page=1" },
    ];

    const links2 = [
        { title: "منابع", link: "/source?sortBy=title&page=1" },
        { title: " نویسندگان", link: "/authors?sortBy=a&page=1" },
        { title: "بلاگ", link: "/blog?sortBy=new&page=1" },
        // {title:"About Us",link:'/source?sortBy=title&page=1'},
        { title: " ارتباط با ما", link: "/contactus" },
    ];

    // const links2=['Link Item','Book page','Genres 223','Hussain genres']
    return (
        <div className="listGroup">
            <div>
                {/* <h1></h1> */}
                <div className="listGroup ">
                    <ul>
                        {links1.map((item, i) => (
                            <Link key={i} to={item.link}>
                                <li>{item.title}</li>
                            </Link>
                        ))}
                    </ul>
                    <ul style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                        {links2.map((item, i) => (
                            <Link key={i} to={item.link}>
                                <li>{item.title}</li>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="resourceWrapper">
                <h1> وب سایت های دیگر</h1>
                <ul>
                    <li>
                        <a href="https://fifthgalaxy.org/" target="_blank" rel="noreferrer">
                            شمش الضحی
                        </a>
                    </li>

                    {/* <li>
                        {" "}
                        <a href="http://ashura.photo/" target="_blank" rel="noreferrer">
                            ASHURA PHOTO
                        </a>
                    </li> */}

                    {/* <li> <Link to="/source?sortBy=title&page=1&sourceType=61768b3b3abdb0508019b634">Articles</Link></li> */}

                    {/* <li>
                        <a href="https://hussainpodcasts.com/" target="_blank" rel="noreferrer">
                            HUSSAIN PODCASTS
                        </a>
                    </li>
                    <li>
                        <a href="https://hussainsounds.com/" target="_blank" rel="noreferrer">
                            HUSSAIN SOUNDS
                        </a>
                    </li> */}
                </ul>
            </div>
        </div>
    );
}
