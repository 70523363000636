import { saveAs } from "file-saver";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const ChangeDate = (date) => {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const _date = new Date(date);

    const monthName = monthNames[_date.getMonth()];
    const day = _date.getDate();
    const year = _date.getFullYear();

    const dateString = monthName + " " + day + " , " + year;

    return dateString;
};

export const isLogin = () =>
    !!cookies.get("x-access-token", {
        sameSite: "strict",
        path: "/",
    });

export const setToken = (token, id) => {
    cookies.set("x-access-token", token, {
        sameSite: "strict",
        path: "/",
    });

    cookies.set("id", id, {
        sameSite: "strict",
        path: "/",
    });
};
export const removeToken = () => {
    cookies.remove("x-access-token", {
        sameSite: "strict",
        path: "/",
    });
    cookies.remove("id", {
        sameSite: "strict",
        path: "/",
    });
};

export const getToken = () => {
    return cookies.get("x-access-token", {
        sameSite: "strict",
        path: "/",
    });
};

export const getID = () => {
    return cookies.get("id", {
        sameSite: "strict",
        path: "/",
    });
};

export const GoHomeIsNotLogin = () => {
    if (!isLogin()) {
        window.location.replace("/");
    }
};

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const SetBackingRoute = (route) => {
    localStorage.setItem("back", route);
};

export const getBackRoute = () => {
    return localStorage.getItem("back");
};

export const removeBackRoute = () => {
    localStorage.removeItem("back");
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const scrollToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

export const isImg = (str) => {
    let regex = /(https?:\/\/.*\.(?:png|jpg|jpeg))/;

    return regex.test(str);
};

export const DownloadFile = (file, name) => {
    saveAs(file, name);
};

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};
