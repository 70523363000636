// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding: 20px;
}
.nav ul li {
  padding: 12px 20px;
  font-size: 14px;
  font-family: "MontserratMedium";
}
.nav ul li:hover {
  color: #8a9358;
  transition-timing-function: ease-in;
  transition: 0.1s;
}`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,SAAA;EACA,UAAA;EACA,aAAA;AADR;AAEQ;EACI,kBAAA;EACA,eAAA;EACA,+BAAA;AAAZ;AACY;EACI,cAAA;EACA,mCAAA;EACA,gBAAA;AAChB","sourcesContent":["\n.nav{\n    ul{\n        list-style: none;\n        margin: 0;\n        padding: 0;\n        padding: 20px;\n        li{\n            padding:12px 20px ;\n            font-size: 14px;\n            font-family: 'MontserratMedium';\n            &:hover{\n                color: #8a9358;\n                transition-timing-function: ease-in;\n                transition: 0.1s;\n    \n            }\n    \n        }\n       \n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
