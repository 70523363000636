// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputWrapper {
  position: relative;
}
.inputWrapper .inputStyle {
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid #F7F7F7;
  padding: 19px 0 19px 54px;
  color: #192C18;
  width: 100%;
  font-family: "MontserratSemiBold";
}
.inputWrapper .inputStyle:focus {
  border-color: #8a9358;
}
@media screen and (max-width: 1024px) {
  .inputWrapper .inputStyle {
    font-size: 12px;
    padding: 20px 0 20px 20px !important;
  }
}
.inputWrapper .bgInputStyle {
  border-radius: 10px;
  width: auto;
  font-size: 14;
  border: none;
  padding: 30px 33px;
  color: #30331f;
  width: 100%;
}
.inputWrapper .bgInputStyle:focus {
  border-color: #8a9358;
}
.inputWrapper .searchIcon {
  position: absolute;
  top: 21px;
  left: 23px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Input.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAGI;EAGI,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,yBAAA;EACA,cAAA;EACA,WAAA;EACA,iCAAA;AAHR;AAIQ;EAEI,qBAAA;AAHZ;AAMQ;EAfJ;IAgBQ,eAAA;IACA,oCAAA;EAHV;AACF;AAOI;EAGI,mBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AAPR;AAUQ;EAEI,qBAAA;AATZ;AAoBI;EAEI,kBAAA;EACA,SAAA;EACA,UAAA;AAnBR","sourcesContent":["\n\n.inputWrapper{\n    position: relative;\n\n    .inputStyle{\n\n        // background-color: #F7F7F7;\n        border-radius: 10px;\n        font-size: 14px;\n        border: 1px solid #F7F7F7;\n        padding: 19px 0 19px 54px;\n        color: #192C18;\n        width: 100%;\n        font-family: \"MontserratSemiBold\";\n        &:focus{\n\n            border-color: #8a9358;\n        }\n\n        @media screen and (max-width: 1024px){\n            font-size: 12px;\n            padding: 20px 0 20px 20px !important;\n            }\n            \n        }\n    \n    .bgInputStyle{\n\n        // background-color: #F7F7F7;\n        border-radius: 10px;\n        width: auto;\n        font-size: 14;\n        border: none;\n        padding: 30px 33px;\n        color: #30331f;\n        width: 100%;\n\n\n        &:focus{\n\n            border-color: #8a9358;\n        }\n    \n\n\n\n\n\n    }\n    \n    \n    .searchIcon{\n    \n        position: absolute;\n        top: 21px;\n        left: 23px;\n    \n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
