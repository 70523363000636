import React, { useRef } from "react";
import "./../../styles/Footer.scss";
import { Row, Col } from "react-grid-system";
import Input from "../Input";
import Button from "../Button";
import Logo from "./../../assets/images/footerLogo.svg";
import { Link } from "react-router-dom";
import SecondFooter from "./SecondFooter";

function Footer() {
    const myInput = useRef("");

    const handleClick = () => {
        console.log(myInput.current.value);
    };
    return (
        <>
            <footer className="footer rtl">
                <div className="container">
                    <Row>
                        <Col md={6} lg={4} xl={4} xxl={4} xs={12} sm={12}>
                            <h1>به خبرنامه بپیوندید </h1>
                            <p className="text">
                                در صورتی که علاقه مند به دانستن بیشتر در مورد حسین هستید، خود را ترک
                                کنید آدرس ایمیل در بخش "خبرنامه" تا به شما اطلاع دهیم درباره مطالب
                                جالب جدید درباره حسین به صورت هفتگی. اطلاعات شما برای ما مهم و با
                                ارزش است و نخواهد بود به شخص ثالث یا شرکت های تبلیغاتی داده می شود.
                            </p>

                            <div className="footerNews">
                                <Input
                                    placeholder="ایمیل"
                                    style={{ flex: 3, paddingRight: "5px" }}
                                    myRef={myInput}
                                    clickHandle={handleClick}
                                />
                                <Button style={{ flex: 1 }} text="پیوستن" />
                            </div>
                        </Col>
                        <Col md={6} lg={4} xl={4} xxl={4} xs={12} sm={12}>
                            <div className="wrapperFooter">
                                <h1>لینک های سریع </h1>
                                <ul>
                                    <li>
                                        <Link to="/">خانه</Link>
                                    </li>
                                    <li>
                                        <Link to="/aboutus">درباره ما</Link>
                                    </li>
                                    <li>
                                        <Link to="/contactus">تماس باما </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        {/* <Col md={6} lg={2} xl={2} xxl={2} xs={12} sm={12}>
                            <div className="wrapperFooter">
                                <h1>کتابخانه</h1>
                                <ul>
                                    <li>
                                        <Link to="/">دسته بندی ها</Link>
                                    </li>
                                    <li>
                                        <Link to="/">زبان ها</Link>
                                    </li>
                                    <li>
                                        <Link to="/">نویسندگان</Link>
                                    </li>
                                    <li>
                                        <Link to="/">مقالات</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col> */}
                        <Col md={6} lg={4} xl={4} xxl={4} xs={12} sm={12}>
                            <h1>درباره ما</h1>
                            <p className="text">
                                این وب سایت یکی از خدمات سایت fifthgalaxy.org می باشد. ما هستیم
                                همیشه در تلاش برای جمع آوری بزرگترین و به روزترین مجموعه است کتاب
                                ها، مقالات و پایان نامه ها در مورد حسین و وقایع عاشورا. شما می
                                توانید با ارسال خود ما را در گسترش معارف حسینی یاری کنید محتوا.
                                مطالب شما در کتابخانه آنلاین امام قرار خواهد گرفت حسین (ع) پس از
                                بررسی و مطالعه. لازم به ذکر است که محتوای تکراری و نادرست پذیرفته
                                نخواهد شد.
                            </p>
                            <img src={Logo} alt="logo" />
                        </Col>
                    </Row>
                </div>
            </footer>
            <SecondFooter />
        </>
    );
}
export default Footer;
