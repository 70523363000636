import React from "react";
import "./../../styles/Button.scss";
import { DownloadIcon, ReadOnlineIcon, SaveIcon, Close, PrintIcon, SavedIcon } from "./../Icon";
function Button({ text, click, size, _style, icon, theme, isDisable }) {
    return (
        <button
            onClick={click}
            className={`buttonStyle ${size}Button ${theme}`}
            style={_style}
            disabled={isDisable}
        >
            <div className="wrapperButton">
                {icon === "download" && (
                    <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        <DownloadIcon color="white" />{" "}
                    </div>
                )}
                {icon === "read" && (
                    <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        <ReadOnlineIcon />{" "}
                    </div>
                )}
                {icon === "save" && (
                    <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        <SaveIcon />{" "}
                    </div>
                )}
                {icon === "saved" && (
                    <div style={{ paddingLeft: "10px" }}>
                        {" "}
                        <SavedIcon />{" "}
                    </div>
                )}
                {icon === "close" && (
                    <div style={{ display: "flex" }}>
                        {" "}
                        <Close color="#8a9358" height="20" />{" "}
                    </div>
                )}
                {icon === "print" && (
                    <div style={{ display: "flex", paddingLeft: "10px" }}>
                        {" "}
                        <PrintIcon />{" "}
                    </div>
                )}
                <p>{text}</p>
            </div>
        </button>
    );
}

Button.defaultProps = {
    size: "fat",
    text: "Search",
};

export default Button;
