/* eslint-disable */

import React, { useState, useEffect } from "react";

// Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
import { Query } from "../../queries";
import Spinner from "../../components/Spinner";

import PdfReader from "../../components/PdfReader";
import "./../../styles/Pdf.scss";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { StarIcon } from "../../components/Icon";
import { isLogin, SetBackingRoute } from "../../helper";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function ReadOnline(props) {
    const BOOK_ID = props.match.params.BOOK_ID;

    const [bookdetail, setsetDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(true);

    const [savedBooks, setSavedBooks] = useState([]);

    const [isSave, checkSave] = useState(false);
    const history = useHistory();

    const getBookDetail = () => {
        Query(
            {
                url: `books/${BOOK_ID}`,
                method: "GET",
            },

            (response) => {
                setsetDetail(response.data);
                setLoading(false);
            },
            (error) => {}
        );
    };

    const getSavedBooks = () => {
        setLoading(true);

        Query(
            {
                url: "save_book",
                method: "GET",
            },

            (response) => {
                setSavedBooks(response.data.items);

                let found = response.data.items.some((el) => el.bookId._id == BOOK_ID);

                checkSave(found);
            },
            (error) => {}
        );
    };

    useEffect(() => {
        getBookDetail();

        if (isLogin()) {
            getSavedBooks();
        }
    }, [BOOK_ID]);

    function handlePrint() {
        // printJs('https://disk1.ashura.photo/uploads/ashura/PDFTRON_about.pdf')

        var frame = document.getElementById("frame");
        frame.contentWindow.focus();
        frame.contentWindow.print();
    }

    const handleSave = () => {
        if (isLogin()) {
            if (isSave) {
                // setFound(false)

                let _id =
                    savedBooks.find((el) => el.bookId._id == BOOK_ID) &&
                    savedBooks.find((el) => el.bookId._id == BOOK_ID).id;

                Query(
                    {
                        url: `save_book/${_id}`,
                        method: "DELETE",
                    },
                    (response) => {
                        toast.info("Item Unsaved!");
                        checkSave(false);
                    },
                    (error) => {
                        toast.error("Couldnot Unsaved");
                        checkSave(false);
                    }
                );
            } else {
                Query(
                    {
                        url: "save_book",
                        method: "POST",
                        data: {
                            bookId: BOOK_ID,
                        },
                    },
                    (response) => {
                        checkSave(true);

                        toast.success(" ذخیره شد !");
                    },
                    (error) => {
                        toast.error(" ذخیره نشد");
                        checkSave(false);
                    }
                );
            }
        } else {
            SetBackingRoute(history.location.pathname);

            toast.info(
                <p>
                    لطفا ابتدا وارد شوید.
                    <Link to="/login">ورود</Link>{" "}
                </p>
            );
        }
    };

    return (
        <>
            {loading ? (
                <Spinner />
            ) : (
                <div>
                    {display && (
                        <header className="readHeader rtl">
                            <Button
                                text=""
                                click={() => setDisplay(false)}
                                _style={{
                                    padding: "15px",
                                    borderRadius: "5px",
                                    height: "-webkit-fill-available",
                                    display: "flex",
                                    flex: "1",
                                }}
                                icon="close"
                                theme="lightTheme"
                            />
                            <div className="sourceDetail">
                                <Link to={`/source/${bookdetail._id}`}>
                                    <img src={bookdetail.imageOnTheCover} alt={bookdetail.title} />
                                </Link>
                                <div className="titleSection">
                                    <Link to={`/source/${bookdetail._id}`}>
                                        <h1>{bookdetail.title}</h1>
                                    </Link>
                                    <div className="writersSection">
                                        <p className="titleHeader">نویسنده</p>
                                        {bookdetail.writer &&
                                            bookdetail.writer.map((item, i) => (
                                                <Link
                                                    key={i}
                                                    to={`/authors/${item._id}`}
                                                    className="authorLink"
                                                >
                                                    {" "}
                                                    <img
                                                        src={item.image}
                                                        alt={item.title}
                                                        width="20px"
                                                    />
                                                    <p className="greenTitle"> {item.title}</p>
                                                </Link>
                                            ))}

                                        <p className="titleHeader" style={{ paddingLeft: "10px" }}>
                                            زبان
                                        </p>
                                        <p className="greenTitle">
                                            {bookdetail.language && bookdetail.language.title}
                                        </p>
                                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                                            <StarIcon />
                                        </div>

                                        <p style={{ marginLeft: "10px" }}>
                                            {" "}
                                            {Number(bookdetail.rating)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="headerButtons">
                                <Button
                                    text={"ذخیره"}
                                    click={handleSave}
                                    _style={{ fontSize: "14px", marginLeft: "15px" }}
                                    icon={isSave ? "saved" : "save"}
                                    theme="lightTheme"
                                />

                                {/* <Button
                                    text="پرینت"
                                    _style={{ fontSize: "14px" }}
                                    icon="print"
                                    click={handlePrint}
                                    theme="lightTheme"
                                /> */}
                            </div>
                        </header>
                    )}

                    <div className="_mt2 ">
                        <PdfReader file={bookdetail.book_pdf} />
                    </div>
                </div>
            )}

            <iframe
                src={bookdetail.book_pdf}
                title="pdf"
                id="frame"
                style={{ display: "none" }}
            ></iframe>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                // autoClose={true}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
                theme={"colored"}
            />
        </>
    );
}
