// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpinnerOverlay {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SpinnerOverlay .SpinnerContainer {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #8a9358;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Spinner.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AAAI;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,yBAAA;EACA,uCAAA;EACA,+CAAA;AAER;AADQ;EACI;IACA,iCAAA;EAGV;AACF","sourcesContent":[".SpinnerOverlay{\n    height: 30vh;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    .SpinnerContainer{\n        display: inline-block;\n        width: 50px;\n        height: 50px;\n        border: 3px solid rgba(195, 195, 195, 0.6);\n        border-radius: 50%;\n        border-top-color: #8a9358;\n        animation: spin 1s ease-in-out infinite;\n        -webkit-animation: spin 1s ease-in-out infinite;\n        @keyframes spin {\n            to {\n            -webkit-transform: rotate(360deg);\n            }\n        }\n        @-webkit-keyframes spin {\n            to {\n            -webkit-transform: rotate(360deg);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
