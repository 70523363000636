import React from "react";
import "./../../styles/Navbar.scss";
export default function NavSideBar() {
    return (
        <div className="nav">
            <ul>
                <li>دسته بندی </li>
                <li>منابع</li>

                <li>نویسندگان</li>
                <li>مقاله ها</li>

                <li>بلاگ</li>
                <li> درباره ما</li>
                <li>ارتباط با ما</li>
            </ul>
        </div>
    );
}
