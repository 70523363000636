/* eslint-disable */

import React, { useEffect } from "react";
import { isLogin, scrollToTop } from "../../helper";
import { useHistory } from "react-router-dom";
import FormCard from "../../components/Auth/FormCard";
import TextCard from "../../components/Auth/TextCard";
import "./../../styles/Auth.scss";
import { Row, Col } from "react-grid-system";
import SecondFooter from "../../components/Layout/SecondFooter";

export default function SignUp() {
    const history = useHistory();

    useEffect(() => {
        scrollToTop();

        if (isLogin()) {
            history.push("/");
        }
    }, []);

    const textList = [];

    return (
        <>
            <div style={{ minHeight: "100vh" }}>
                <div className="authWrapper rtl">
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <div style={{ display: "flex", marginBottom: "50px" }}>
                                <TextCard
                                    textList={textList}
                                    text="برای استفاده کامل از امکانات سایت لطفا ثبت نام کنید"
                                    type="signup"
                                />

                                <FormCard type="signup" />
                            </div>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </div>
            </div>
            <SecondFooter />
        </>
    );
}
