// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifyWrapper {
  padding: 40px 0;
  text-align: center;
}
.verifyWrapper .backHome {
  display: flex;
  align-items: center;
  padding: 41px 0 0 0;
  justify-content: center;
}
.verifyWrapper .backHome span {
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/Service.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AAER;AADQ;EACI,iBAAA;AAGZ","sourcesContent":[".verifyWrapper{\n    padding: 40px 0;\n    text-align: center;\n    .backHome{\n        display: flex;\n        align-items: center;\n        padding: 41px 0 0 0 ;\n        justify-content: center;\n        span{\n            padding-left: 5px;\n        }\n\n    }\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
