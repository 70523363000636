import React from "react";
import { Link } from "react-router-dom";
import BlueLogo from "./../../assets/images/blue.png";
import GreenLogo from "./../../assets/images/green.png";
import Logo from "./../../assets/images/Logo2.png";
import { SmallLeftIcon } from "./../Icon";
export default function TextCard({ type, text, textList }) {
    return (
        <div
            className="formTextCard"
            style={{
                background:
                    "radial-gradient(110.6% 393.85% at -23.46% 140.28%, #30331f  0%, #8a9358 100%)",
                borderRadius: type === "login" ? "10px 0 0 10px" : "0px 10px 10px 0px",
            }}
        >
            <div className="backHome" style={{ left: type === "signup" && "0" }}>
                <Link to="/">
                    <span>بازگشت به خانه</span>
                    <SmallLeftIcon />
                </Link>
            </div>

            <img
                className="textCardLogo"
                src={type === "login" ? Logo : Logo}
                alt="ImamhusssainLibrary"
            />
            <p className="cardText">{text}</p>
            <ul className="cardList">
                {textList && textList.map((item, i) => <li key={i}> {item} </li>)}
            </ul>
        </div>
    );
}
