import React, { useState } from "react";
import { SearchIcon } from "./../Icon";
import "./../../styles/Input.scss";

function Input({
    type,
    placeholder,
    icon,
    value,
    style,
    bgSize,
    clickHandle,
    myRef,
    disabled,
    rows,
    changeHandle,
}) {
    const [iconColor, handleColor] = useState("#30331f");

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            clickHandle?.();
        }
    };

    return (
        <div className="inputWrapper" style={style}>
            {type === "textArea" ? (
                <textarea
                    disabled={disabled}
                    rows={rows}
                    onChange={changeHandle}
                    onFocus={() => handleColor("#8a9358")}
                    onBlur={() => handleColor("#30331f")}
                    ref={myRef}
                    className={bgSize ? "bgInputStyle" : "inputStyle"}
                    style={{
                        padding: icon ? "19px 15px 19px 54px" : "19px 15px 19px 27px",
                        backgroundColor: "#f7f7f2",
                    }}
                    placeholder={placeholder}
                    defaultValue={value}
                />
            ) : (
                <input
                    onChange={changeHandle}
                    disabled={disabled}
                    onKeyDown={handleKeyDown}
                    onFocus={() => handleColor("#8a9358")}
                    onBlur={() => handleColor("#30331f")}
                    ref={myRef}
                    className={bgSize ? "bgInputStyle" : "inputStyle"}
                    style={{
                        padding: icon ? "19px 15px 19px 54px" : "19px 15px 19px 27px",
                        backgroundColor: type === "search" ? "#F7F7F7" : "#f7f7f2",
                    }}
                    type={type}
                    placeholder={placeholder}
                    defaultValue={value}
                />
            )}
            {icon && (
                <div className="searchIcon " onClick={clickHandle}>
                    <SearchIcon color={iconColor} />
                </div>
            )}
        </div>
    );
}

Input.defaultProps = {
    type: "text",
    placeholder: "جست وجو",
    disabled: false,
};

export default Input;
